import { useMemo } from 'react';
import { Token } from '@my/sdk';
import { ChainId } from '@my/sdk';
import { useSelector } from 'react-redux';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { AppState } from '../../index';
import { deserializeToken } from './helpers';

export default function useUserAddedTokens(): Token[] {
  const { chainId } = useActiveWeb3React();
  const serializedTokensMap = useSelector<AppState, AppState['user']['tokens']>(({ user: { tokens } }) => tokens);

  return useMemo(() => {
    if (!chainId) return [];
    return Object.values(serializedTokensMap?.[chainId as ChainId] ?? {}).map(deserializeToken);
  }, [serializedTokensMap, chainId]);
}
