import React from 'react';
import { SvgProps } from '@my/ui';

const IconIn: React.FC<SvgProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className="design-iconfont">
      <path
        d="M5.24199109,2.99840467 L5.24199109,0.392856441 C5.24199109,-0.0101071673 4.98479305,-0.121565905 4.67184876,0.145078239 L0.236645198,4.01354163 C0.162622356,4.06988811 0.102711643,4.14266585 0.0616439884,4.22617269 C0.0205767087,4.309642 -0.000523584649,4.40151453 0,4.49454999 C0.000190312486,4.58754794 0.0216000988,4.67930792 0.0626077308,4.76281475 C0.103614988,4.84632158 0.163138928,4.91936193 0.236645198,4.97638366 L4.67184876,8.85513723 C4.98648119,9.12178888 5.24199109,9.00947106 5.24199109,8.60735528 L5.24199109,6.00007389 L5.99220207,6.00007389 C7.37993107,5.92725863 8.7066243,5.40641009 9.7732684,4.51567054 C10.8399125,3.62496851 11.5889605,2.41239378 11.9080947,1.05989446 C11.9527367,0.868466492 11.9845113,0.674266213 12.0032685,0.478595374 C12.025552,0.27711357 11.9286525,0.233386902 11.7854979,0.373138925 C11.7854979,0.373138925 11.7443448,0.410863305 11.710882,0.448587685 C10.1930909,1.98900739 8.15062485,2.90094251 5.99047641,3.00271881 L5.24026543,3.00271881 L5.24199109,2.99840467 Z"
        transform="scale(-1 1) rotate(45 -4.48515998 -10.57893655)"
        fill="#CFDADA"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default IconIn;
