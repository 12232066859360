import React from 'react';
import AccountEVM from './AccountEVM';

const Bsc: React.ReactNode = (
  <>
    <AccountEVM />
  </>
);

export default Bsc;
