import React from 'react';
import { SvgProps } from '@my/ui';

const IconKarsier: React.FC<SvgProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" className="design-iconfont">
      <path
        d="M5.26160777,0 L5.26160777,0 C6.24160456,0 7.17567617,0.41540743 7.83160128,1.14352556 C8.4875264,1.87164368 8.80369597,2.84388534 8.7015065,3.81853967 L8.41665115,6.56252597 C8.38373802,6.89376251 8.56895432,7.20821918 8.87460895,7.34003789 C9.18026357,7.4718566 9.53608176,7.39073016 9.75438954,7.13944819 L15.0404394,1.00964962 C15.5978322,0.366916708 16.4072258,-0.00158767132 17.2579841,0 L17.2579841,0 C18.4105547,-0.00381467659 19.458427,0.668079197 19.935894,1.71710687 C20.413361,2.76613454 20.2318082,3.99760369 19.4719231,4.86421119 L15.1774584,9.73559817 C14.3581079,10.6702776 14.2650899,12.0374942 14.9502953,13.0745355 L18.5236073,18.4831813 C19.2456304,19.5746443 19.3096667,20.9745223 18.6903067,22.1273458 C18.0709466,23.2801693 16.8683361,23.9995351 15.5596694,24 L15.5596694,24 C14.3150822,23.9987586 13.1613726,23.3480882 12.5164047,22.2836564 L8.80246789,16.1538578 C8.6325521,15.8738072 8.30309099,15.7331437 7.98330574,15.8041165 C7.6635205,15.8750892 7.42449841,16.1419212 7.38900845,16.4675593 L6.90583609,20.9134661 C6.70598935,22.6640477 5.22428352,23.9859466 3.46233161,23.9855769 L3.46233161,23.9855769 C2.48105809,23.9847775 1.54613322,23.5679699 0.889657648,22.838629 C0.233182079,22.109288 -0.0832828655,21.1358082 0.0188271211,20.1598615 L1.80368023,3.0973869 C1.99059158,1.330491 3.4848712,-0.00797218834 5.26160777,0 Z"
        transform="translate(8 6)"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default IconKarsier;
